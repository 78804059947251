import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BusinessLogger,
	BusinessLoggerSymbol,
	IPageWillMountHandler,
	IPageWillUnmountHandler,
	IPropsStore,
	PageFeatureConfigSymbol,
	pageIdSym,
	contextIdSymbol,
	Props,
	SiteFeatureConfigSymbol,
	WixBiSession,
	WixBiSessionSymbol,
	CurrentRouteInfoSymbol,
} from '@wix/thunderbolt-symbols'
import { ITpa, TpaPageConfig } from './types'
import { name } from './symbols'
import {
	name as tpaCommonsName,
	MasterPageTpaPropsCacheSymbol,
	MasterPageTpaPropsCache,
	TpaCommonsSiteConfig,
	TpaCompData,
	ITpaSrcBuilder,
	TpaSrcBuilderSymbol,
	TpaContextMappingSymbol,
	ITpaContextMapping,
	BuildTpaSrcOptions,
} from 'feature-tpa-commons'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import _ from 'lodash'
import { ICurrentRouteInfo } from 'feature-router'

export const Tpa = withDependencies(
	[
		Props,
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		SessionManagerSymbol,
		BusinessLoggerSymbol,
		pageIdSym,
		contextIdSymbol,
		MasterPageTpaPropsCacheSymbol,
		TpaSrcBuilderSymbol,
		TpaContextMappingSymbol,
		WixBiSessionSymbol,
		CurrentRouteInfoSymbol,
	],
	(
		props: IPropsStore,
		{ widgetsClientSpecMapData, isMobileView, viewMode, externalBaseUrl }: TpaCommonsSiteConfig,
		{ widgets, tpaInnerRouteConfig }: TpaPageConfig,
		sessionManager: ISessionManager,
		businessLogger: BusinessLogger,
		pageId: string,
		contextId: string,
		{ cacheProps, getCachedProps }: MasterPageTpaPropsCache,
		tpaSrcBuilder: ITpaSrcBuilder,
		tpaContextMapping: ITpaContextMapping,
		wixBiSession: WixBiSession,
		currentRouteInfo: ICurrentRouteInfo
	): ITpa & IPageWillMountHandler & IPageWillUnmountHandler => {
		tpaContextMapping.registerTpasForContext(contextId, Object.keys(widgets))
		const tpas = _.pickBy(widgets, ({ widgetId, isOOI }) => !isOOI && widgetsClientSpecMapData[widgetId])

		const getCompId = (id: string, { templateId }: TpaCompData) => templateId ?? id

		const buildSrc = (id: string, tpaCompData: TpaCompData) => {
			const { widgetUrl, mobileUrl } = widgetsClientSpecMapData[tpaCompData.widgetId]
			const baseUrl = isMobileView ? mobileUrl || widgetUrl : widgetUrl
			const options: Partial<BuildTpaSrcOptions> = { extraQueryParams: {} }
			if (tpaCompData.isSection) {
				options.tpaInnerRouteConfig = tpaInnerRouteConfig
				if (viewMode === 'site') {
					options.extraQueryParams!['section-url'] = `${externalBaseUrl}/${tpaInnerRouteConfig.tpaPageUri}/`
					options.extraQueryParams!.target = '_top'
				} else {
					options.extraQueryParams!['section-url'] = baseUrl
					options.extraQueryParams!.target = '_self'
				}
			}
			return tpaSrcBuilder.buildSrc(id, pageId, tpaCompData, baseUrl, options)
		}

		const rebuildTpasSrc = () => {
			Object.entries(tpas).forEach(([id, tpaCompData]) => {
				props.update({
					[id]: {
						src: buildSrc(id, tpaCompData),
					},
				})
			})
		}

		return {
			async pageWillMount() {
				sessionManager.addLoadNewSessionCallback(({ reason }) => {
					if (reason === 'memberLogin') {
						rebuildTpasSrc()
					}
				})
				Object.entries(tpas).forEach(([id, tpaCompData]) => {
					const { widgetId } = tpaCompData
					const { appDefinitionId, appDefinitionName, appPage } = widgetsClientSpecMapData[widgetId]
					const reportIframeStartedLoading = _.once(() => {
						const routeInfo = currentRouteInfo.getCurrentRouteInfo()

						const now = Date.now()
						const tts = now - wixBiSession.initialRequestTimestamp
						businessLogger.logger.log(
							{
								appId: appDefinitionId,
								widget_id: widgetId,
								instance_id: getCompId(id, tpaCompData),
								src: 42,
								// APP_IFRAME_START_LOADING
								evid: 642,
								tts,
								pid: routeInfo ? routeInfo.pageId : null,
							},
							{ endpoint: 'ugc-viewer' }
						)
					})

					const defaultProps = {
						title: appPage.name ?? appDefinitionName,
						appDefinitionName,
						isMobileView,
						reportIframeStartedLoading,
					}

					const cachedProps = getCachedProps(id)
					props.update({
						[id]: {
							...defaultProps,
							src: buildSrc(id, tpaCompData),
							...(cachedProps as any),
						},
					})
				})
			},
			pageWillUnmount() {
				if (pageId === 'masterPage') {
					Object.keys(tpas).forEach(cacheProps)
				}
			},
			rebuildTpasSrc,
		}
	}
)
